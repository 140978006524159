import React, { useContext, useState } from "react";
import { isValidEmail } from "../../../utils/validations";
import { mxpEmailCaptured } from "../../../utils/mixpanelEvents/mixpanelEvents";
import EmailOnlyFormProvider from "../../../components/shared/EmailOnlyFormProvider";
import { EmailOnlyFormProps } from "../../../components/shared/EmailOnlyForm/Email.type";
import { formInitialCtx } from "../../../contexts/FormOnlyEmail.context";
import setRouteName from "../../../utils/getLocationName";
import { iterableSubmit } from "../../../utils/iterableSubmit";
import { IterableDataType } from "@types";
import generateUuId from "../../../utils/generateUuId";
import { getFormattedTime } from "../../../assets/util-scripts/helpers";
import styles from "../../Forms/EmailCaptureForm/EmailCaptureForm.module.scss";
import { ResourceDownloadEmailContext } from "@contexts/ResourceDownloadEmailContext";
import { leadCapture } from "@utils/segment/leadCapture";

type Props = {
  ctaText: string;
  handleCustomFinish: () => void | null;
  fileName?: string | null;
};

const EmailCaptureForm: React.FC<Props> = ({
  ctaText,
  handleCustomFinish,
  fileName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState(false);

  const { hasError, setHasError, email, setEmail } = useContext(
    ResourceDownloadEmailContext
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) setHasError(false);
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    window.analytics?.identify({
      account_status: "lead",
      email,
    });

    const analyticsData = {
      text: ctaText,
      resource: fileName,
      email,
    };

    leadCapture(analyticsData);

    try {
      setIsLoading(true);

      if (email.length === 0) {
        setHasError(true);
        return;
      }

      if (!isValidEmail(email)) {
        setHasError(true);
        return;
      }

      const formData: IterableDataType = {
        Digioh_Source: window.location.href,
        digioh_source: window.location.pathname.replace(
          /^(?:\/\/|[^/]+)*\//,
          ""
        ),
        email,
        eventName: "newUserSignup",
        SIGN_UP_SOURCE: "Website - Gated Resource",
        gatedContent: {
          SUBMIT_URL: window.location.href,
          SUBMITTED_AT: getFormattedTime(),
        },
      };

      const { status } = await iterableSubmit(formData);

      if (status === 200) {
        mxpEmailCaptured(email, ctaText);

        const user_id = generateUuId();

        window.dataLayer.push({
          event: "lead_capture",
          location: `modal-${setRouteName()}`,
          email,
          user_id,
        });

        setEmail("");
        handleCustomFinish();
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleFocus = () => {
    setCustomError(true);
  };

  const handleBlur = () => {
    if (!isValidEmail(email)) {
      setCustomError(false);
      setHasError(true);
    }
  };

  const ctx: EmailOnlyFormProps = {
    ...formInitialCtx,
    buttonStyle: "primary-blue",
    buttonText: ctaText,
    email,
    handleChange,
    handleSubmit,
    withArrow: true,
    isLoading,
    placeholder: "Email",
    externalClassNames: styles.defualtEmailCapture,
    hideInput: false,
    handleFocus: handleFocus,
    handleBlur: handleBlur,
    removeBorder: customError,
    inputError: hasError,
  };

  return (
    <>
      <EmailOnlyFormProvider ctxInitialVal={ctx} />
      {hasError && (
        <small className={styles.errorMessage}>
          {!email
            ? "Your email is required."
            : "Your email is an invalid format."}
        </small>
      )}
    </>
  );
};

export default EmailCaptureForm;
